import { Box } from '@mui/material';

export const EditRelationsActionsBox = ({children}: any) => {
	return (
		<Box sx={{
			width: '100%',
			height: '40px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			backgroundColor: '#D3D3D3',
			paddingRight: '3vw',
		}}>
			{children}
		</Box>
	)
}
