import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useWindowWidth } from './useWindowWidth';


export const useColumnsWidth = (columns: number): { columnsWidth: number } => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const window = useWindowWidth(mobile ? 200 : -100);
	const _wW = window.width * 0.7;

	const columnWidth = Math.floor(_wW / columns);

	return {columnsWidth: columnWidth};
}