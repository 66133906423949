import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CardFilters } from '../../../Shared/components/CardFilters';
import { useSubmit } from '../../../Shared/hooks/useSubmit';
import { NodeFull } from '../../../Shared/interfaces';
import { DASHBOARD_EDIT_URL } from '../../../Shared/constants/routes';


export default function DashboardFilters(props: { dashboard: any, reRender: () => void }) {
	const { dashboard, reRender } = props;
	const [filterFields, setFilterFields] = React.useState(dashboard.filterFields);

	const dashboardData = {
		id: dashboard.id,
		filterFields: filterFields,
	}

	const { callAPI, resetState } = useSubmit<NodeFull>(DASHBOARD_EDIT_URL, dashboardData);


	const handleSubmitEdit = () => {
		callAPI();
		reRender();
		resetState();
	}

	return <Box
		sx={{
			padding: '20px 20px 0px 20px',
			borderRadius: '20px',
			border: '1px solid #264a5d50',
			display: 'flex',
			flexDirection: 'column',
		}}
	>
		<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>Filters</Typography>
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexWrap: 'wrap',
			}}
		>
			<CardFilters onChange={setFilterFields} filterFields={filterFields} />
			<Button color={'success'} onClick={handleSubmitEdit}>Edit</Button>
		</Box>
	</Box>
}
