import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';
import { createColumns } from '../../../../utils/dataGrid/createColumn';
//import { IconButton } from '@mui/material';
//import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import { useNavigate } from 'react-router-dom';

export default function UserTable(props: { users: any }) {
	//const navigate = useNavigate();
	const { users } = props;

	const { columnsWidth } = useColumnsWidth(6);

	const columns = createColumns([
		'lastName',
		'firstName',
		'email',
		'id',
		'createdOn',
		'modifiedOn',
	], columnsWidth);

	const rows = users.map((user: any) => {
		const userRow = {
			key: user.id,
			name: `${user.firstName} ${user.lastName}`,
			more: user.id,
			...user,
		};

		return userRow;
	});

	return <DetailsTable rows={rows} columns={columns} />;
}
