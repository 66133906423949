import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { EditEntityCardRelationships } from '../EditEntityCardRelationships';
import { EditRelationsActionsBox } from '../../../Shared/components';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';
import { createColumns } from '../../../../utils/dataGrid/createColumn';
//import { IconButton } from '@mui/material';
//import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import { useNavigate } from 'react-router-dom';

export default function CardTable(props: { cards: any, entity: {name: string, id: string}, reRender: Function }) {
	const { cards, entity, reRender } = props;

	const { columnsWidth } = useColumnsWidth(5);

	const columns = createColumns([
		'name',
		'embedId',
		'id',
		'createdOn',
		'modifiedOn'
	], columnsWidth);

	const rows = cards.map((card: any) => {
		return {
			key: card.id,
			more: card.id,
			...card,
		};
	});

	return <>
		<EditRelationsActionsBox>
			<EditEntityCardRelationships entity={entity} reRenderComponent={reRender} />
		</EditRelationsActionsBox>
		<DetailsTable rows={rows} columns={columns} />
	</>
}
