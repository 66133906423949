import React from 'react';

export const useReRender = (): [boolean, () => void] => {
	const [reRender, setReRender] = React.useState(false);

	const handleReRender = () => {
		setReRender(!reRender);
	};

	return [reRender, handleReRender];
}
