import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

export const DetailsTable = (props: { rows: any; columns: any }) => {
	const { rows, columns } = props;
	const theme = useTheme();

	return (
		<Box
			id="box"
			sx={{
				height: '100%',
				width: '100%',
				minWidth: 0,
				minHeight: 0,
				borderRadius: '0px',

				'& .MuiDataGrid-columnHeaders': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					borderRadius: '0px'
				},
				'& .MuiDataGrid-menuIconButton': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				},
				'& .MuiDataGrid-sortIcon': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
				},
				'& .MuiDataGrid-columnSeparator': {
					display: 'none',
				},
			}}
		>
			<DataGrid
				sx={{
					width: '100%',
					minWidth: 0,
					minHeight: 0,
				}}
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 25,
						},
					},
				}}
				pageSizeOptions={[25, 50, 100]}
				disableRowSelectionOnClick
				autoPageSize
			/>
		</Box>
	);
};
