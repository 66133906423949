import { ROLES } from './roles';


export const getDisplayRoleValue = (value: any) => {
	switch (value) {
		case ROLES.USER:
			return 'Client';
		case ROLES.AUTOMATIT:
			return 'Automatit';
		case ROLES.ADMIN:
			return 'Admin';
		default:
			return 'WRONG ROLE VALUE'
	}
}

export const getDisplayIsPublicValue = (value: boolean) => {
	return value ? "Public" : "Private"
}

export const getDateFormatted = (value: string): string => {
	return new Date(value).toLocaleDateString();
}

const DISPLAY = {
	USER: {
		ROLE: getDisplayRoleValue,
	},
	DASHBOARD: {
		IS_PUBLIC: getDisplayIsPublicValue,
		DATE_FORMATTED: getDateFormatted,
	}
};

export default DISPLAY;
