import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ROLES } from '../../../Shared/constants/roles';
import React, { JSX } from 'react';
import { HandleChangeFunction } from '../../../Shared/components/NodeDetailsFields/EditableField';
import { DefaultDashboardSelect } from '../DefaultDashboardSelect';

export const EditRoleField = (value: string, onChange: HandleChangeFunction): JSX.Element => {
	return <FormControl style={{ marginTop: '16px' }} fullWidth>
		<InputLabel>Role</InputLabel>
		<Select
			labelId="role-select-label"
			id="role-select"
			label="Role"
			onChange={e => onChange(e.target.value)}
			value={value}
		>
			<MenuItem value={ROLES.USER}>User</MenuItem>
			<MenuItem value={ROLES.AUTOMATIT}>Automatit</MenuItem>
			<MenuItem value={ROLES.ADMIN}>Admin</MenuItem>
		</Select>
	</FormControl>
}

export const EditDefaultDashboardId = (value: string, onChange: HandleChangeFunction, userId: string): JSX.Element => {
	return <DefaultDashboardSelect userId={userId} dashboardId={value} setDashboardId={onChange} />
}
