//Node Modules
import React, { useState } from 'react';

//Material UI
import { Box, Typography } from '@mui/material';
import { formatDate } from '../../../../utils/dateFormatter';
import EditableField from '../../../Shared/components/NodeDetailsFields/EditableField';
import { NodeBase, NodeFull } from '../../../Shared/interfaces';
import { useSubmit } from '../../../Shared/hooks/useSubmit';
import { USER_EDIT_URL } from '../../../Shared/constants/routes';
import { EditDefaultDashboardId, EditRoleField } from './CustomEditFields';

//Internal
import { DataField } from '../../../Shared/components/NodeDetailsFields/DataField';
import { getDisplayRoleValue } from '../../../Shared/constants/displayValueFunctions';
import { stringValidation } from '../../constants/validationSchemas';

export const UserData = (props: { data: any, updateTitleCallback: Function }) => {
	const { data, updateTitleCallback } = props;

	const defaultDashboardData: { name: string, id: string } = data.defaultDashboard;

	const [firstName, setFirstName] = useState<string>(data.firstName as string);
	const [lastName, setLastName] = useState<string>(data.lastName as string);
	const [dashboardId, setDashboardId] = useState<string | undefined>(defaultDashboardData.id as string);
	const [role, setRole] = useState<number>(data.role as number);

	const userData: NodeBase = {
		...data,
		firstName: firstName,
		lastName: lastName,
		email: data.email,
		role: role,
		defaultDashboardId: dashboardId,
	};

	const { callAPI, resetState } = useSubmit<NodeFull>(USER_EDIT_URL, userData);

	const handleSubmitEdit = () => {
		callAPI();
		resetState();
		updateTitleCallback(`${firstName} ${lastName}`)
	}

	return (
		<Box
			sx={{
				padding: '2% 2%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				minHeight: '100%',
			}}
		>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Basic Info
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<EditableField label={'First Name'} value={userData.firstName} onSubmit={handleSubmitEdit} onChange={setFirstName} validationSchema={stringValidation} />
					<EditableField label={'Last Name'} value={userData.lastName} onSubmit={handleSubmitEdit} onChange={setLastName} validationSchema={stringValidation} />
					<DataField label={'Id'} value={data.id} />
					<DataField label={'Status'} value={data.isActive ? 'Active' : 'Disabled'} />
					<EditableField
						label={'Role'}
						value={userData.role}
						onSubmit={handleSubmitEdit}
						onChange={(value) => setRole(parseInt(value))}
						customEditComponent={EditRoleField}
						displayValue={getDisplayRoleValue(userData.role)}
					/>
					<EditableField
						label={'Default dashboard'}
						value={userData.defaultDashboardId}
						displayValue={defaultDashboardData.name}
						onSubmit={handleSubmitEdit}
						onChange={setDashboardId}
						customEditComponent={(value, onChange) => EditDefaultDashboardId(value, onChange, data.id)}
						validationSchema={stringValidation}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
					margin: '10px 0',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Timestamps
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Created On'} value={formatDate(data.createdOn)} />
					<DataField label={'Created By'} value={data.createdBy} />
					<DataField label={'Modified On'} value={formatDate(data.modifiedOn)} />
					<DataField label={'Modified By'} value={data.modifiedBy} />
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>Usage</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Entities'} value={data.entities?.length ?? 0} />
					<DataField label={'Cards'} value={data.cards?.length ?? 0} />
					<DataField label={'Instances'} value={data.instances?.length ?? 0} />
				</Box>
			</Box>
		</Box>
	);
};
