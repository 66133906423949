import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_OBSERVE_URL } from '../../../Shared/constants/routes';
import React from 'react';

export const OpenDashboardButton = (props: {id: any}) => {
	const { id } = props;
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(`${DASHBOARD_OBSERVE_URL}/${id}`);
	}

	return <Button color="success" variant="contained" onClick={handleClick}>Open</Button>
}

export const openDashboardAction = () => {
	return {
		field: 'open',
		headerName: 'Open Dashboard',
		sortable: false,
		width: 100,
		disableClickEventBubbling: true,
		renderCell: (params: any) => {
			return <OpenDashboardButton id={params.id} />
		}
	}
}