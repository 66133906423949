import React, { useState } from 'react';

import {
	EditRelationsActionsBox,
	EditRelationsIconWrapper,
	NodeDialog,
} from '../../../Shared/components';
import { Box, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { EntityCardKVP } from '../../../Shared/interfaces/EntityCardKVP';
import { ENTITIES_EDIT_URL } from '../../../Shared/constants/routes';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import CardFiltersKVP from './CardFiltersKVP';
import { editEntityValidator } from '../../constants/validationSchemas';

type EntityKVP = { key: string; value: string };

interface WithCardOnEntity {
	identifierField: string;
}

export const EntityKVPTable = (props: {data: any, reRender: Function}): React.ReactElement => {
	const { data, reRender } = props;

	// EDIT_KVP
	const [KVPs, setKVPs] = useState<EntityKVP[]>((data.cardFiltersKVP as EntityCardKVP[]) || []);

	const relatedCardsIdentifyFields: string[] = [];
	if (data.cards?.length) {
		data.cards.forEach((card: WithCardOnEntity) => {
			if (card.identifierField) {
				relatedCardsIdentifyFields.push(card.identifierField);
			}
		});
	}

	const editData = {
		entityData: {
			id: data.id,
			cardFiltersKVP: KVPs,
		},
		graphData: {
			level: data.level,
			vertical: data.vertical,
		}
	}

	const handleKVPsChange = (newKVPs: EntityKVP[]) => {
		setKVPs(newKVPs);
	};

	const onClose = () => {
		setKVPs(data.cardFiltersKVP as EntityCardKVP[]);
	}
	// END_EDIT_KVP

	// TABLE
	const columns = [
		{ field: 'key', headerName: 'Key', minWidth: 600 },
		{ field: 'value', headerName: 'Value', minWidth: 600 },
	];

	const rows = KVPs.map((kvp: {key: string, value: string}) => {
		return {
			id: kvp.key,
			key: kvp.key,
			value: kvp.value,
		}
	})
	// END_TABLE

	return <>
		<EditRelationsActionsBox>
			<NodeDialog
				ButtonComponent={EditRelationsIconWrapper}
				url={ENTITIES_EDIT_URL}
				node={editData}
				dialogText={'Edit'}
				dialogTitle={'Edit Lookup Values'}
				reRenderComponent={reRender}
				buttonColor={'primary'}
				Icon={EditOutlinedIcon}
				dialogContentText="Please include any relevant values for data lookup in DOMO."
				validationSchema={editEntityValidator}
				resetContent={onClose}
			>
				<Box sx={{ marginTop: '10px' }}>
					<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Lookup Values</Typography>
					<CardFiltersKVP KVPs={KVPs} onChange={handleKVPsChange} options={relatedCardsIdentifyFields} />
				</Box>
			</NodeDialog>
		</EditRelationsActionsBox>
		<DetailsTable columns={columns} rows={rows} />
	</>
}