import React, { JSX } from 'react';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';


export const EditDashboardIsPublic = (value: string, onChange: (value: string) => void): JSX.Element => {

	const handleChange = (event: any) => {
		const value = event.target.value;
		onChange(value);
	}

	return (<FormControl>
		<Typography>Public</Typography>
		<Select
			onChange={handleChange}
			defaultValue={value}
			labelId="is-public"
			id="is-public"
			label="Public"
		>
			<MenuItem value={'1'}>Public</MenuItem>
			<MenuItem value={'2'}>Private</MenuItem>
		</Select>
	</FormControl>)
}
