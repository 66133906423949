import { ImpersonateUser } from '../../../Shared/components';

export const impersonateAction = () => {
	return {
		field: 'impersonate',
		headerName: 'Impersonate User',
		sortable: false,
		width: 250,
		disableClickEventBubbling: true,
		renderCell: (params: any) => {
			return <ImpersonateUser userId={params.id} />
		}
	}
}