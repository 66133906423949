import { Alert, Snackbar } from '@mui/material';
import { SyntheticEvent, useState } from 'react';


const ValidationNotification = (props: { message: string }) => {
	const {message} = props;
	const [open, setOpen] = useState<boolean>(true);

	const handleClose = (_event?: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
			<Alert
				onClose={handleClose}
				severity="warning"
				variant="filled"
				sx={{
					width: '100%',
					fontWeight: 'bold',
					color: 'common.white',
					borderRadius: '5px',
				}}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default ValidationNotification;