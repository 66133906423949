//Node Modules

//Internal
import { NodeFull, NodeType } from '../../../interfaces';
import { createInfoEntries } from '../../NodeBar/utils';
import { GridValidRowModel } from '@mui/x-data-grid';

export interface NodeBarsSearchCriteria {
	property: keyof NodeFull;
	value: string;
}

export const generateNodeRows = (
	nodes: NodeFull[],
	searchCriteria: NodeBarsSearchCriteria,
	nodeType: NodeType,
	reRenderComponent: Function,
): GridValidRowModel[] => {
	if (!nodes.length) {
		return [];
	}

	let filteredNodes: NodeFull[] = [];

	if (searchCriteria.value) {
		filteredNodes = applySearchCriteria(searchCriteria, nodes);
	}

	if (!searchCriteria.value && filteredNodes.length === 0) {
		filteredNodes = nodes;
	}

	const bars = filteredNodes.map((node: NodeFull) => {
		return {
			id: node.id,
			name: node.name,
			...createInfoEntries(nodeType.infoFields, node),
			deleted: node.deleted,
			reRenderComponent: reRenderComponent,
		};
	});

	if (bars.length > 0) return bars;
	return [];
};

const applySearchCriteria = (searchCriteria: NodeBarsSearchCriteria, nodes: NodeFull[]): NodeFull[] => {
	const searchProp = searchCriteria.property;
	return nodes.filter((node: NodeFull): boolean | undefined => {
		switch (searchProp) {
			case 'id':
				return node[searchProp].includes(searchCriteria.value);
			case 'name':
				const searchCriteriaLower = searchCriteria.value.toLowerCase();
				return !!node[searchProp] && node[searchProp]?.toLowerCase().includes(searchCriteriaLower);
			default:
				return true;
		}
	});
}
