import { HandleChangeFunction } from '../../../Shared/components/NodeDetailsFields/EditableField';
import React, { JSX } from 'react';
import { Box, TextField } from '@mui/material';
import SelectColumnName from '../../../Shared/components/DOMO/SelectColumnName';

export const EditIdentifierField = (value: string, onChange: HandleChangeFunction): JSX.Element => {
	return (
		<Box>
			<TextField
				disabled
				value={value}
				fullWidth
				variant="standard"
				id="identifierField"
				label="Identity Column name"
			/>
			<Box sx={{ marginTop: '10px' }}>
				<SelectColumnName handleSelect={onChange} />
			</Box>
		</Box>
	)
}
