interface InfoField {
	key: string,
	displayKey?: string,
	getDisplayValue?: (value: any) => string,
}

const createInfoEntry = (infoField: {key: string, displayKey?: string, getDisplayValue?: (value: any) => string}, node: any): string => {
	const {key, getDisplayValue} = infoField;
	return getDisplayValue ? getDisplayValue(node[key]) : node[key] || '___'
}

export const createInfoEntries = (infoFields: InfoField[], node: any) => {
	return infoFields.reduce((accumulatedObj: any, currentEntry: InfoField) => {
		accumulatedObj[currentEntry.key] = createInfoEntry(currentEntry, node);
		return accumulatedObj;
	}, {})
}
