//Node Modules
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

//Material UI
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal
import { ResponseHandler } from '../../../Shared/components';
import { useFetch } from '../../../Shared/hooks/useFetch';
import TitleBar from '../../../Shared/components/TitleBar';
import { OptionsNav } from './OptionsNav';
import { CardDetailsContent } from './CardDetailsContent';
import { useWindowHeight } from '../../../Shared/hooks/useWindowHeight';
import { useWindowWidth } from '../../../Shared/hooks/useWindowWidth';

export const CardDetails = ({ nodeType }: any) => {
	const params = useParams();
	const { id } = params;
	const theme = useTheme();
	const { height } = useWindowHeight(0);
	const { width } = useWindowWidth(0);

	const [tab, setTab] = useState(0);

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	const nodeDetailsRequest = useFetch(`${nodeType.route}/${id}/management`, true, [id]);

	const [title, setTitle] = useState<string>('');

	const updateTitleCallback = useCallback((newTitle: string) => {
		setTitle(newTitle)
	}, [setTitle])

	useEffect(() => {
		setTitle(nodeDetailsRequest.data?.card?.name || '');
	}, [nodeDetailsRequest.data?.card?.name]);

	return (
		<ResponseHandler request={nodeDetailsRequest}>
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<TitleBar title={`Cards: ${title}`} />
				<Box sx={{ padding: standardScreen ? '0px 2%' : '0px' }}>
					<Box
						sx={{
							width: standardScreen ? `calc(${width}px - 4% - 250px)` : width,
							height: standardScreen ? `calc(${height}px - 4% - 100px)` : height - 50 - 80,
							background: '#fefefe',
							display: 'flex',
							overflow: 'hidden',
							border: standardScreen ? '2px solid #00000023' : 'none',
							flexDirection: standardScreen ? 'row' : 'column',
							borderRadius: standardScreen ? '20px' : '0',
							paddingRight: tab === 0 ? '10px' : '0px',
							[theme.breakpoints.down('md')]: {
								paddingRight: '0px',
							},
						}}
					>
						<OptionsNav tab={tab} setTab={setTab} />
						<CardDetailsContent tab={tab} id={id} nodeType={nodeType} data={nodeDetailsRequest.data} updateTitleCallback={updateTitleCallback} />
					</Box>
				</Box>
			</Box>
		</ResponseHandler>
	);
};
