//Node Modules
import React, { ReactElement, useState } from 'react';
import { Navigate } from 'react-router-dom';

//Material UI
import { Box, Button } from '@mui/material';

// Internal
import { IMPERSONATED_DASHBOARDS_URL } from '../constants/routes';

export const ImpersonateUser = (props: { userId: string }): ReactElement => {
	const [clicked, setClicked] = useState<boolean>(false);
	const { userId } = props;

	const onClickHandler = (): void => {
		setClicked(true);
	};

	return (
		<>
			{/*<Box sx={{ border: `1px solid #00000040`, borderRadius: '4px', width: '37%', height: '2px', margin: '2px' }} />*/}

			<Box sx={{ marginLeft: '8px' }}>
				<Button
					variant="contained"
					color="primary"
					onClick={onClickHandler}
				>
					View User Dashboards
				</Button>
				{clicked && <Navigate to={IMPERSONATED_DASHBOARDS_URL} replace={true} state={{ impersonatedUserId: userId }} />}
			</Box>
		</>
	);
};
