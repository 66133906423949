import React, { JSX, useRef, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { NodeIconButton } from '../NodeIconButton';
import ValidationNotification from './ValidationNotification';
import { DataField } from './DataField';
import { useSnackbar } from 'notistack';
import { Schema } from 'yup';

export type HandleChangeFunction = (value: string) => void;

export type EditComponentElementFunction = (value: string, onChange: HandleChangeFunction) => JSX.Element

export interface EditableFieldProps {
	label: string,
	value: string,
	displayValue?: string,
	onChange: (value: string) => void,
	onSubmit: () => void,
	onClose?: () => void,
	customEditComponent?: EditComponentElementFunction,
	validationSchema?: Schema,
}

const EditableField = (props: EditableFieldProps) => {
	const { value, displayValue, label, onChange, onSubmit, customEditComponent, validationSchema } = props;

	const refValue = useRef(value);

	const [ edit, setEdit ] = useState<boolean>(false);
	const [validationError, setValidationError] = useState<string | null>(null);

	const { enqueueSnackbar } = useSnackbar();

	const handleClickEdit = () => {
		setEdit(true);
	};

	const handleClickCloseEdit = () => {
		setValidationError(null)
		setEdit(false);
		onChange(refValue.current);
	};

	const handleClickConfirm = () => {
		const doSubmit = (): void => {
			onSubmit();
			refValue.current = value;
			setEdit(false);
			enqueueSnackbar('Success', { variant: 'success' });
		}
		if (validationSchema) {
			validationSchema
				.validate(value, { abortEarly: true })
				.then(() => {
					doSubmit();
				})
				.catch((error: any) => {
					setValidationError(error.errors[0]);
				});
		} else {
			doSubmit();
		}

	}

	const editComponent : JSX.Element = customEditComponent ? customEditComponent(value, onChange) : defaultEditComponent(value, onChange);

	return edit ?
		<Box sx={{ display: 'flex', flexBasis: '50%', marginBottom: '25px' }}>
			<Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{label}</Typography>
			<Box sx={{margin: '0 20px'}}>
				{editComponent}
				{validationError ? <ValidationNotification message={validationError} /> : null}
			</Box>
			<Box>
				<NodeIconButton Icon={CloseIcon} setOpen={handleClickCloseEdit} sx={{color: 'red'}} />
				<NodeIconButton Icon={DoneIcon} setOpen={handleClickConfirm} sx={{color: 'green'}} />
			</Box>
		</Box> :
		<Box sx={{ display: 'flex', flexBasis: '50%', marginBottom: '25px' }}>
			<DataField value={value} label={label} displayValue={displayValue}/>
			<Box><NodeIconButton Icon={EditOutlinedIcon} setOpen={handleClickEdit} /></Box>
		</Box>
}

export default EditableField;

const defaultEditComponent = (value: string, onChange: HandleChangeFunction) => {
	return <TextField
		id="standard-helperText"
		value={value}
		variant="standard"
		onChange={(e) => onChange(e.target.value)}
	/>
}
