import { capitalize } from '@mui/material';

/**
 * Converts a string to title case.
 *
 * @param {string} str - The string to be converted.
 * @returns {string} - The converted string in title case.
 *
 * @example
 * // returns "Hello World"
 * titleCase("hello world");
 */
export const titleCase = (str: string): string => {
	return str.toLowerCase().split(' ').map(word => capitalize(word)).join(' ');
};

// This function pluralizes a string by adding 's' to the end
// of the word, unless the final letter is 'y', in which case
// the 'y' is replaced with 'ies'. The function takes a string
// as an argument and returns a string.
export const dumbPluralize = (string: string): string => {
	const finalLetter: string = string.slice(-1);

	switch (finalLetter) {
		case 'y':
			return string.slice(0, -1) + 'ies';
		default:
			return string + 's';
	}
};

/**
 * Converts a camelCase string to capitalize words, separated by spaces.
 *
 * @param {string} str - The camelCase string to be converted.
 * @returns {string} The converted string with words separated by spaces.
 */
export const camelCaseToCapital = (str: string): string => {
	let result = str.replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
}
