// CARDS
export const CARD_ROOT_URL = '/cards';
export const CARD_LIST_URL = `${CARD_ROOT_URL}`;
export const CARD_DELETE_URL = `${CARD_ROOT_URL}/delete`;
export const CARD_EDIT_URL = `${CARD_ROOT_URL}/edit`;
export const CARD_CREATE_URL = `${CARD_ROOT_URL}/create`;
export const CARD_LIST_FOR_USER = `${CARD_ROOT_URL}/list`;
export const CARD_DETAILS_URL = `${CARD_ROOT_URL}/:id`;
export const CARD_DOMO_COLUMNS = '/cards/columns';
export const CARD_DOMO_DATASETS = '/cards/datasets';

// CARD_INSTANCES
export const CARD_INSTANCE_ROOT_URL = '/card-instances';
export const CARD_INSTANCE_DOMO_CRED_URL = `${CARD_INSTANCE_ROOT_URL}/domo-cred`;
export const CARD_INSTANCE_CREATE_URL = `${CARD_INSTANCE_ROOT_URL}/create`;
export const CARD_INSTANCE_DELETE_URL = `${CARD_INSTANCE_ROOT_URL}/delete`;
export const CARD_INSTANCE_EDIT_URL = `${CARD_INSTANCE_ROOT_URL}/edit`;
// export const CARD_INSTANCE_FILTERS_URL = `${CARD_INSTANCE_ROOT_URL}/filters`;
export const CARD_INSTANCE_CANVAS_UPDATE = `${CARD_INSTANCE_ROOT_URL}/canvas`;

// DASHBOARDS
export const DASHBOARD_ROOT_URL = '/dashboards';
export const DASHBOARD_LIST_FOR_USER = `${DASHBOARD_ROOT_URL}/user`;
export const DASHBOARD_MANAGEMENT_LIST = `${DASHBOARD_ROOT_URL}/list`;
export const DASHBOARD_OBSERVE_URL = `${DASHBOARD_ROOT_URL}`;
export const DASHBOARD_OPEN = `${DASHBOARD_ROOT_URL}/:id`;
export const DASHBOARD_MANAGEMENT_DETAILS = `${DASHBOARD_MANAGEMENT_LIST}/:id`;
export const DASHBOARD_IMPERSONATED = `${DASHBOARD_ROOT_URL}/impersonate`; // {/id}
export const DASHBOARD_CREATE_URL = `${DASHBOARD_ROOT_URL}/create`;
export const DASHBOARD_ADMIN_DELETE_URL = `${DASHBOARD_ROOT_URL}/admin_delete`;
export const DASHBOARD_REMOVE_RELATION_URL = `${DASHBOARD_ROOT_URL}/remove_relation`;
export const DASHBOARD_DOMO_CRED_URL = `${DASHBOARD_ROOT_URL}/domo-cred`;
export const DASHBOARD_EDIT_URL = `${DASHBOARD_ROOT_URL}/edit`;
// export const DASHBOARD_URL = `/:dashboardId`;
// export const NEW_DASHBOARD_ROOT_URL = `/new`;
export const NEW_DASHBOARD_URL = `/dashboards/:dashboardId`;

// ENTITIES
export const ENTITIES_ROOT_URL = '/entities';
export const ENTITIES_LIST_URL = `${ENTITIES_ROOT_URL}`;
export const ENTITIES_DELETE_URL = `${ENTITIES_ROOT_URL}/delete`;
export const ENTITIES_RESTORE_URL = `${ENTITIES_ROOT_URL}/restore`;
// export const ENTITIES_SYNC_CREATE_URL = `${ENTITIES_ROOT_URL}/sync-create`;
export const ENTITIES_EDIT_URL = `${ENTITIES_ROOT_URL}/edit`;
export const ENTITIES_CREATE_URL = `${ENTITIES_ROOT_URL}/create`;
export const EDIT_ENTITY_CARDS_URL = `${ENTITIES_ROOT_URL}/card-set`;
export const EDIT_ENTITY_CHILDREN_URL = `${ENTITIES_ROOT_URL}/sub-entities-set`;
export const ENTITY_DETAILS_URL = `${ENTITIES_ROOT_URL}/:id`;

// USERS
export const USERS_ROOT_URL = '/users';
export const USER_LIST_URL = `${USERS_ROOT_URL}`;
export const USER_DELETE_URL = `${USERS_ROOT_URL}/delete`;
export const USER_RESTORE_URL = `${USERS_ROOT_URL}/restore`;
export const USER_CREATE_URL = `${USERS_ROOT_URL}/create`;
export const USER_EDIT_URL = `${USERS_ROOT_URL}/edit`;
export const USER_SET_DASHBOARD_URL = `${USERS_ROOT_URL}/set-dashboard`;
export const EDIT_USER_ENTITIES_URL = `/permissions`;
export const USER_DETAILS_URL = `${USERS_ROOT_URL}/:id`;

// NODES
export const NODE_ROOT_URL = '/nodes';
export const NODE_DETAILS_URL = `${NODE_ROOT_URL}/:id`;
export const NODE_DELETE_REDUNDANT_URL = `${NODE_ROOT_URL}/delete-redundant`;
export const NODE_LEVELS_URL = `${NODE_ROOT_URL}/level`;
export const NODE_VERTICALS_URL = `${NODE_ROOT_URL}/vertical`;

// DEVELOPMENT
// export const DEV_URL = '/test-post';

//Impersonation
export const IMPERSONATED_DASHBOARDS_URL = '/impersonated-dashboards';
export const IMPERSONATED_DASHBOARD_URL = '/impersonated-dashboards/:dashboardId';

// ADMIN PAGE SETTINGS
export const ADMIN_ROOT_URL = '/admin';
export const ADMIN_SETTINGS_URL = `${ADMIN_ROOT_URL}/settings`;

// LOGGER ROUTES
export const LOG_ROOT_URL = '/logs';
export const LOG_CREATE_URL = LOG_ROOT_URL;
export const LOG_CHECK_CONNECTION_URL = `${LOG_ROOT_URL}/check-connection`;

// BUG REPORT
export const BUG_REPORT_URL = '/bug-report';