//Node Modules
import React, { ReactElement, useState } from 'react';

//Internal
import UserEntityRelationshipSelect from './UserEntityRelationshipSelect';
import { EDIT_USER_ENTITIES_URL } from '../../../Shared/constants/routes';
import { NodeDialog, NodeIconButton } from '../../../Shared/components';
import { NodeBase, NodeBasic } from '../../../Shared/interfaces';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const EditUserEntityRelationships = (props: { user: NodeBasic; reRenderComponent: Function }): ReactElement => {
	const { reRenderComponent, user } = props;
	const [entitySet, setEntitySet] = useState<string[]>([]);

	const resetComponent = (): void => {
		setEntitySet([]);
		reRenderComponent();
	};

	const entitySetPayload: NodeBase = {
		userId: user.id,
		entityIds: entitySet,
	};

	const onClose = (): void => {
		setEntitySet([])
	}

	return (
		<NodeDialog
			ButtonComponent={EditUsersButton}
			url={EDIT_USER_ENTITIES_URL}
			node={entitySetPayload}
			dialogText={'Edit users Entities'}
			dialogTitle={`Edit Entities Managed By ${user.firstName} ${user.lastName}`}
			reRenderComponent={resetComponent}
			dialogContentText="Please select all entities this user should have permissions to view and edit."
			buttonColor={'primary'}
			resetContent={onClose}
			Icon={EditOutlinedIcon}
		>
			<UserEntityRelationshipSelect id={user.id} entityChildSet={entitySet} setEntityChildSet={setEntitySet} />
		</NodeDialog>
	);
};

const EditUsersButton = (props: any) => {
	return <NodeIconButton {...props} sx={{
		padding: '5px 10px',
		margin: '10px',
		backgroundColor: '#7FB3D5',
		color: 'black',
		borderRadius: '20px',
		'&:hover': {
			backgroundColor: '#5499C7',
		}
	}} />
}