import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useLocation, useParams } from 'react-router-dom';

import { DASHBOARD_IMPERSONATED } from '../../../Shared/constants/routes';
import { useFetch } from '../../../Shared/hooks/useFetch';
import { ResponseHandler } from '../../../Shared/components';
import DashboardCanvas from '../DashboardCanvas';
import { Box } from '@mui/material';
import { ImpersonatedDashboardBar } from './ImpersonatedDashboardBar';
import { EmbedDashboard } from '../EmbedDashboard';

interface LocationState {
	impersonatedUserId?: string;
}

const getRequestUrl = (dashboardId?: string): string => {
	if (dashboardId) {
		return `${DASHBOARD_IMPERSONATED}/${dashboardId}`;
	}
	return DASHBOARD_IMPERSONATED;
};

function useLocationState<T>(): T | null {
	const location = useLocation();
	return location.state as T;
}

export const ImpersonatedDashboard = (): any => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [reRender, setReRender] = useState<boolean>(false);

	const { dashboardId } = useParams<{ dashboardId?: string }>();
	const state = useLocationState<LocationState>();
	const impersonatedUserId = state?.impersonatedUserId;

	useEffect(() => {
		if (!impersonatedUserId) {
			enqueueSnackbar('impersonatedUserId not provided', { variant: 'error' });
			navigate("/")
		}
	}, [navigate, impersonatedUserId, enqueueSnackbar]);


	const reRenderComponent = (): void => {
		setReRender(!reRender);
	};

	let requestUrl = getRequestUrl(dashboardId);

	const dashboardResponse = useFetch<any>(
		requestUrl,
		true,
		[reRender],
		{ impersonatedUserId: impersonatedUserId },
		true,
	);

	const getDashboardComponent = (data: any) => {
		if (data.type === 'embedType') {
			return <EmbedDashboard data={data}
								   impersonation={{
									   impersonation: data?.impersonation ?? false,
									   impersonatedUserId: data?.impersonatedUserId ?? -1,
								   }}
								   reRenderComponent={reRenderComponent} defaultDashboardId={data?.defaultDashboardId} />
		} else {
			return <DashboardCanvas
				cardInstances={dashboardResponse.data?.cardInstances}
				impersonatedUserId={impersonatedUserId}
				impersonation={Boolean(impersonatedUserId)}
				dashboardId={dashboardResponse.data?.id}
				reRenderComponent={reRenderComponent}
			/>
		}
	}

	return (
		<ResponseHandler request={dashboardResponse}>
			<Box sx={{ width: '100%' }}>
				<ImpersonatedDashboardBar
					name={dashboardResponse.data?.name || 'Impersonated'}
					currentDashboardId={dashboardResponse.data?.id}
					impersonatedUserId={impersonatedUserId as string}
					impersonatedUserName={dashboardResponse.data?.impersonatedUserName}
				/>
				{dashboardResponse.data && getDashboardComponent(dashboardResponse.data)}
			</Box>
		</ResponseHandler>
	);
};
