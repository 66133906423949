//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

//Internal
import { ENTITIES_DELETE_URL } from '../../Shared/constants/routes';
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { DeleteEntityProps } from '../interfaces';

export const DeleteEntity = (props: DeleteEntityProps): ReactElement => {
	const { reRenderComponent, name, id } = props;

	const entity = {
		name: name,
		deleteEntityId: id,
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={ENTITIES_DELETE_URL}
			node={entity}
			dialogText={'Delete'}
			dialogTitle={'Delete Entity'}
			reRenderComponent={reRenderComponent}
			Icon={CancelRoundedIcon}
		>
			<DialogContentText>{`Are you sure you want to delete entity ${name}?`}</DialogContentText>
		</NodeDialog>
	);
};
