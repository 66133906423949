import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { EditEntityChildrenRelationships } from '../EditEntityChildrenRelationships';
import { EditRelationsActionsBox } from '../../../Shared/components';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';
import { createColumns } from '../../../../utils/dataGrid/createColumn';

export const EntityTable = (props: { entities: any, entity: {name: string, id: string}, reRender: Function }) => {
	const { entities, entity, reRender } = props;

	const { columnsWidth } = useColumnsWidth(4);

	const columns = createColumns([
		'name',
		'id',
		'createdOn',
		'modifiedOn'
	], columnsWidth);

	const rows = entities.map((entity: any) => {
		return {
			key: entity.id,
			more: entity.id,
			...entity,
		};
	});

	return <>
		<EditRelationsActionsBox>
			<EditEntityChildrenRelationships entity={entity} reRenderComponent={reRender} />
		</EditRelationsActionsBox>
		<DetailsTable rows={rows} columns={columns} />
	</>
};
