import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';
import { createColumns } from '../../../../utils/dataGrid/createColumn';

export interface InstanceData {
	id: string,
	name: string,
	dashboardId: string,
	createdOn: string,
	modifiedOn: string,
}

export const InstanceTable = (props: { instances: InstanceData[] }) => {
	// const [instanceViewOpen, setInstanceViewOpen] = useState<boolean>(false);
	// const [selectedInstance, setSelectedInstance] = useState<string>('');
	// const [selectedName, setSelectedName] = useState<string>('');

	// const navigate = useNavigate();
	const { instances } = props;

	// const triggerInstanceViewer = (instanceId: string) => {
	// 	setSelectedInstance(instanceId);
	// 	getInstanceName(instanceId);
	// 	setInstanceViewOpen(true);
	// };

	// const getInstanceName = (instanceId: string) => {
	// 	const instance = instances.filter((instance: any) => instance.id === selectedInstance);
	// 	setSelectedName(instance[0].name);
	// };

	const { columnsWidth } = useColumnsWidth(5);

	const columns = createColumns([
		'name',
		'id',
		'dashboardId',
		'createdOn',
		'modifiedOn'
	], columnsWidth);

	const rows = instances.map((instance: InstanceData) => {
		return {
			key: instance.id,
			more: instance.id,
			...instance,
		};
	});

	return (
		<>
			<DetailsTable rows={rows} columns={columns} />
			{/* <InstanceViewer
				instanceViewOpen={instanceViewOpen}
				setInstanceViewOpen={setInstanceViewOpen}
				selectedInstance={selectedInstance}
				selectedName={selectedName}
			/> */}
		</>
	);
};
