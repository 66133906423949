import React, { useState } from 'react';
import { NodeDialog, NodeIconButton } from '../../../Shared/components';
import { DASHBOARD_EDIT_URL } from '../../../Shared/constants/routes';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormControl, Typography } from '@mui/material';
import { SelectConnectedUsers } from '../SelectConnectedUsers';

export const EditDashboardConnectedUsers = (props: {users: any[], dashboardId: string, reRenderComponent: () => void}) => {
	const { users, dashboardId, reRenderComponent } = props;

	const initUsersId = users.map((user) => user.id);
	const [connectedUsers, setConnectedUsers] = useState<string[]>(initUsersId);

	const dashboardData = {
		id: dashboardId,
		users: connectedUsers,
	}

	return <NodeDialog
		ButtonComponent={EditUsersButton}
		url={DASHBOARD_EDIT_URL}
		node={dashboardData}
		dialogText={'Edit'}
		dialogTitle={'Edit Dashboard'}
		reRenderComponent={reRenderComponent}
		buttonColor={'primary'}
		variant={'contained'}
		Icon={EditOutlinedIcon}
	>
		<FormControl>
			<Typography>Users</Typography>
			<SelectConnectedUsers selected={connectedUsers} onChange={setConnectedUsers} />
		</FormControl>
	</NodeDialog>
}

const EditUsersButton = (props: any) => {
	return <NodeIconButton {...props} sx={{
		padding: '5px 10px',
		margin: '10px',
		backgroundColor: '#7FB3D5',
		color: 'black',
		borderRadius: '20px',
		'&:hover': {
			backgroundColor: '#5499C7',
		}
	}} />
}
