import { camelCaseToCapital } from '../strings.utils';

const dateFormatter = new Intl.DateTimeFormat(
	'en-US',
	{ dateStyle: 'medium', timeStyle: 'short', timeZone: 'US/Arizona' },
);

export const createColumn = (field: string, width: number) => {
	if (field === 'createdOn' || field === 'modifiedOn') {
		// Special case for createdOn and modifiedOn
		return {
			field,
			headerName: formatHeaderName(field),
			minWidth: width,
			align: 'left',
			valueFormatter: formatDate,
		};
	} else {
		// General case
		return {
			field,
			headerName: camelCaseToCapital(field),
			minWidth: width,
		};
	}
}

const formatDate = (params: any) => {
	const date = new Date(params?.value);
	return dateFormatter.format(date);
}

export const createColumns = (fields: string[], width: number) => fields.map(field => {
	return createColumn(field, width);
});

const formatHeaderName = (field: string): string => {
	const specialColumnNames: { [index: string]: string } = {
		createdOn: 'Created',
		modifiedOn: 'Modified'
	};

	return specialColumnNames[field] || camelCaseToCapital(field);
}
