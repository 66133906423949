//Node Modules
import React, { ReactElement, useState } from 'react';

//Internal
import { EDIT_ENTITY_CHILDREN_URL } from '../../../Shared/constants/routes';
import { NodeDialog, EditRelationsIconWrapper } from '../../../Shared/components';
import ChildrenRelationshipSelect from './ChildrenRelationshipSelect';
import { NodeBase, NodeBasic } from '../../../Shared/interfaces';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const EditEntityChildrenRelationships = (props: { entity: NodeBasic; reRenderComponent: Function }): ReactElement => {
	const { reRenderComponent, entity } = props;
	const [entityChildSet, setEntityChildSet] = useState<string[]>([]);

	const resetComponent = (): void => {
		setEntityChildSet([]);
		reRenderComponent();
	};

	const entityPayload: NodeBase = {
		name: entity.name,
		entityId: entity.id,
		entityIds: entityChildSet,
	};

	const onClose = (): void => {
		setEntityChildSet([])
	}

	return (
		<NodeDialog
			ButtonComponent={EditRelationsIconWrapper}
			url={EDIT_ENTITY_CHILDREN_URL}
			node={entityPayload}
			dialogText={'Edit Children'}
			dialogTitle={`Edit Entities Managed By ${entity.name}`}
			dialogContentText="Please select all children this entity manages."
			reRenderComponent={resetComponent}
			buttonColor={'primary'}
			resetContent={onClose}
			Icon={EditOutlinedIcon}
		>
			<ChildrenRelationshipSelect id={entity.id} entityChildSet={entityChildSet} setEntityChildSet={setEntityChildSet} />
		</NodeDialog>
	);
};
