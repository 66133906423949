import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';
import { createColumns } from '../../../../utils/dataGrid/createColumn';
//import { IconButton } from '@mui/material';
//import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import { useNavigate } from 'react-router-dom';

export const InstanceTable = (props: { instances: any }) => {
	const { instances } = props;

	const { columnsWidth } = useColumnsWidth(5);

	const columns = createColumns([
		'name',
		'id',
		'dashboardId',
		'createdOn',
		'modifiedOn'
	], columnsWidth);

	const rows = instances.map((instance: any) => {
		return {
			key: instance.id,
			more: instance.id,
			...instance,
		};
	});

	return <DetailsTable rows={rows} columns={columns} />;
};
