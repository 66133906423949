import { EmbedCard, ResponseHandler } from '../../Shared/components';
import { FetchState } from '../../Shared/interfaces';
import { useFetch } from '../../Shared/hooks/useFetch';
import { DASHBOARD_DOMO_CRED_URL } from '../../Shared/constants/routes';
import { Box } from '@mui/material';
import { SetDefaultDashboard } from './SetDefaultDashboard';
import React from 'react';


export const EmbedDashboard = (props: {data: any, impersonation?: any, defaultDashboardId: string, reRenderComponent?: Function}) => {
	const {data, impersonation, defaultDashboardId, reRenderComponent} = props;

	const payload: { id: string; impersonatedUserId?: string } = {
		id: data.id,
	};

	const impersonationMod = impersonation && impersonation.impersonation;

	if (impersonationMod) {
		payload.impersonatedUserId = impersonation.impersonatedUserId;
	}

	const request: FetchState = useFetch(`${DASHBOARD_DOMO_CRED_URL}`, true, null, payload, true);

	return (
		<ResponseHandler request={request}>
			<Box sx={{height: '90%'}}>
				{
					request.success &&
					!request.loading &&
					<EmbedCard
						embedUrl={request.data.embedUrl}
						embedToken={request.data.embedToken}
						resolution={{width: 1200, height: 1800}}
					/>
				}
				{!impersonationMod && (
					<SetDefaultDashboard
						defaultDashboardId={defaultDashboardId}
						currentDashboardId={data.id}
						reRenderComponent={reRenderComponent}
					/>
				)}
			</Box>
		</ResponseHandler>
	);
}