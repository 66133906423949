//Node Modules
import React, { ReactElement } from 'react';

//Material UI
import { DialogContentText } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

//Internal
import { NodeBase } from '../../Shared/interfaces';
import { NodeDialog, NodeIconButton } from '../../Shared/components';
import { DASHBOARD_ADMIN_DELETE_URL } from '../../Shared/constants/routes';

export const DeleteDashboard = (props: { id: string, name: string, reRenderComponent: Function }): ReactElement => {
	const { reRenderComponent, id, name } = props;

	const dashboard: NodeBase = {
		id: id,
	};

	return (
		<NodeDialog
			ButtonComponent={NodeIconButton}
			url={DASHBOARD_ADMIN_DELETE_URL}
			node={dashboard}
			dialogText={'Delete'}
			dialogTitle={'Delete Dashboard'}
			reRenderComponent={reRenderComponent}
			Icon={CancelRoundedIcon}
		>
			<DialogContentText>{`Are you sure you want to delete dashboard: ${name}?`}</DialogContentText>
		</NodeDialog>
	);
};
