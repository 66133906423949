import { Box, Typography } from '@mui/material';
import React from 'react';

export const DataField = (props: { label: string; value: string, displayValue?: string }) => {
	const { label, value, displayValue } = props;
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', flexBasis: '50%', marginBottom: '25px' }}>
			<Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>{label}</Typography>
			<Typography sx={{ fontWeight: 700, fontSize: '16px' }}>{displayValue ? displayValue : value}</Typography>
		</Box>
	);
};
