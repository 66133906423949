//Node Modules
import React, { useRef, useLayoutEffect, ReactElement, useEffect, useState } from 'react';

//Material UI
import { Box, Fab, Tooltip, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal
import { Shape } from '../interfaces/Shapes';
import { CanvasCard } from './CanvasCard';
import { Loading } from '../../Shared/components';
import { HoverData } from '../interfaces/HoverData';
import { useSubmit } from '../../Shared/hooks/useSubmit';
import { CARD_INSTANCE_CANVAS_UPDATE } from '../../Shared/constants/routes';
import { CreateCardInstance } from './CreateCardInstance';
import { SetDefaultDashboard } from './SetDefaultDashboard';
import { RemoveRelationDashboard } from './RemoveRelationDashboard';
import { useSelector } from 'react-redux';
import { getFlagValue } from '../../AdminPanel/Settings/utils';
import { StoreState } from '../../../store/store';
import { ROLES } from '../../Shared/constants/roles';

const DashboardCanvas = ({
	cardInstances,
	impersonation,
	impersonatedUserId,
	dashboardId,
	defaultDashboardId,
	reRenderComponent,
	allowEdit,
}: any): ReactElement => {
	const theme = useTheme();
	const settings = useSelector((state: any) => state.apiSettings);
	const user = useSelector((state: StoreState) => state.user);
	const userIsAdmin = user.role === ROLES.ADMIN;

	const maxCardWidth = getFlagValue(settings, 'max_width') || '1200';
	const maxCardHeight = getFlagValue(settings, 'max_height') || '1200';

	const initHoverData = {
		id: '',
		enabled: false,
		positionX: 0,
		positionY: 0,
		currentlyHovering: false,
		border: 'none',
	};

	const initShapeRecoveryData = {
		id: '',
		positionX: 0,
		positionY: 0,
		width: 300,
		height: 300,
	};

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	const [generating, setGenerating] = useState<boolean>(true);
	const [hover, setHover] = useState<HoverData>(initHoverData);
	const [disablePointer, setDisablePointer] = useState<boolean>(false);

	// eslint-disable-next-line
	const [dimensions, setDimensions] = React.useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	const [shapes, setShapes] = useState<Shape[]>([]);
	const [shapeRecoveryData, setShapeRecoveryData] = useState<any>(initShapeRecoveryData);
	const [scrollPositionY, setScrollPositionY] = useState(0);
	const [scrollPositionX, setScrollPositionX] = useState(0);
	const [scrollHeight, setScrollHeight] = useState(0);
	const [scrollWidth, setScrollWidth] = useState(0);
	const [updated, setUpdated] = useState<boolean>(false);
	const [horizaontalScroll, setHorizontalScroll] = useState<boolean>(false);
	const [verticleScroll, setVerticleScroll] = useState<boolean>(false);
	const ref = useRef<any>();

	const formatShapePost = () => {
		const instances = cardInstances.map((cardInstance: any) => {
			const shapesIndex = shapes.findIndex((shape: Shape) => shape.id === cardInstance.id);

			cardInstance = {
				...cardInstance,
				positionX: shapes[shapesIndex]?.positionX,
				positionY: shapes[shapesIndex]?.positionY,
				width: shapes[shapesIndex]?.width,
				height: shapes[shapesIndex]?.height,
			};
			return cardInstance;
		});

		return { instances: instances };
	};

	const { callAPI } = useSubmit(`${CARD_INSTANCE_CANVAS_UPDATE}`, formatShapePost());

	const maxHeight = standardScreen ? `calc(${window.innerHeight}px *.99 - 100px)` : window.innerHeight - 156 - 80;
	const maxWidth = standardScreen ? `calc(${window.innerWidth}px * .99 - 250px)` : window.innerWidth;

	const generateShapes = (cardInstances: any): Shape[] => {
		let genShapes: Shape[] = [];

		cardInstances.forEach((instance: any, index: number) => {
			const genShape: Shape = {
				id: instance.id,
				positionX: instance.positionX ?? 0,
				positionY: instance.positionY ?? 0,
				width: instance.width ?? 400,
				height: instance.height ?? 300,
			};

			genShapes.push(genShape);
		});

		return genShapes;
	};

	const updateShapes = (shapes: Shape[]) => {
		setShapes(shapes);
		setUpdated(true);
	};

	useLayoutEffect(() => {
		setScrollHeight(ref.current?.scrollHeight);
		setScrollWidth(ref.current?.scrollWidth);
		setHorizontalScroll(ref.current?.scrollWidth > ref.current?.clientWidth);
		setVerticleScroll(ref.current?.scrollHeight > ref.current?.clientHeight);
	}, [shapes, ref.current?.scrollWidth, ref.current?.clientWidth, ref.current?.scrollHeight, ref.current?.clientHeight]);

	const scrollEvent = (e: any) => {
		const target = e.target as HTMLTextAreaElement;

		if (hover.enabled) {
			const scrollIndex = shapes.findIndex((shape: Shape) => shape.id === shapeRecoveryData.id);
			const yDelta = scrollPositionY - target.scrollTop;
			const xDelta = scrollPositionX - target.scrollLeft;

			scrollShape(scrollIndex, yDelta, xDelta);
		}

		setScrollPositionY(target.scrollTop);
		setScrollPositionX(target.scrollLeft);
	};

	const scrollShape = (index: number, yDelta: any, xDelta: any) => {
		const newShapes = shapes.slice();

		let newY = newShapes[index].positionY - yDelta;
		let newX = newShapes[index].positionX - xDelta;

		newShapes[index] = {
			...newShapes[index],
			positionY: newY,
			positionX: newX,
		};

		updateShapes(newShapes);
	};

	const dropShape = (index: number, event: any = null, snap: boolean = false) => {
		const newShapes = shapes.slice();

		let newX = snap ? Math.round(newShapes[index].positionX / 50) * 50 : (newShapes[index].positionX += event.dx);
		let newY = snap ? Math.round(newShapes[index].positionY / 50) * 50 : (newShapes[index].positionY += event.dy);

		newShapes[index] = {
			...newShapes[index],
			positionX: newX,
			positionY: newY,
		};

		updateShapes(newShapes);
	};

	const resizeShape = (index: number, event: any = null) => {
		const newShapes = shapes.slice();

		newShapes[index] = {
			...newShapes[index],
			width: Math.round(event.rect.width / 50) * 50,
			height: Math.round(event.rect.height / 50) * 50,
		};

		updateShapes(newShapes);
	};

	const initMovementCalc = (index: number, changes: any) => {
		const newShapes = shapes.slice();

		newShapes[index] = {
			...newShapes[index],
			...changes,
		};

		const posValid = !isOutOfBounds(newShapes[index]);

		if (!posValid) {
			newShapes[index] = {
				...newShapes[index],
				...shapeRecoveryData,
			};
		}

		//Check for Hover (Circumvent Initial Collision)
		if (hover.currentlyHovering) {
			const snappedShapes = snapShape(newShapes, index).slice();
			const collisionResults = collisionTree(snappedShapes[index], snappedShapes);

			if (collisionResults.cannotMove) {
				newShapes[index] = {
					...newShapes[index],
					...shapeRecoveryData,
				};

				updateShapes(newShapes);
			} else {
				updateShapes(collisionResults.resultingShapes);
			}
		} else {
			const possibleShapesArray = newShapes.slice();
			const collisionResults = collisionTree(possibleShapesArray[index], possibleShapesArray);

			if (collisionResults.cannotMove) {
				console.log('Move Invalid');

				newShapes[index] = {
					...newShapes[index],
					...shapeRecoveryData,
				};

				updateShapes(newShapes);
			} else {
				updateShapes(collisionResults.resultingShapes);
			}
		}
	};

	const determineBestMovement2 = (collidedShape: any, interactingShape: Shape, collision: any, previousMovement: any) => {
		const { left, up, down, right } = collision;
		let movement = { direction: 'right', distance: 0 };

		if (previousMovement) {
			movement.direction = previousMovement.direction;

			switch (previousMovement.direction) {
				case 'left':
					movement.distance = left;
					break;
				case 'right':
					movement.distance = right;
					break;
				case 'up':
					movement.distance = up;
					break;
				case 'down':
					movement.distance = down;
					break;
				default:
					break;
			}

			return movement;
		}

		if (interactingShape.width > shapeRecoveryData.width && interactingShape.height > shapeRecoveryData.height) {
			if (collidedShape.positionX >= shapeRecoveryData.positionX + shapeRecoveryData.width) {
				//If Companion Shape is Right of Original Shape
				movement = { direction: 'right', distance: right };
			} else if (collidedShape.positionY >= shapeRecoveryData.positionY + shapeRecoveryData.height) {
				//If Companytion Shape is Below Original Shape
				movement = { direction: 'down', distance: down };
			}

			return movement;
		}

		if (interactingShape.width > shapeRecoveryData.width) {
			movement = { direction: 'right', distance: right };
			return movement;
		}

		if (interactingShape.height > shapeRecoveryData.height) {
			movement = { direction: 'down', distance: down };
			return movement;
		}

		const dX = collidedShape.positionX - interactingShape.positionX;
		const dY = collidedShape.positionY - interactingShape.positionY;

		if (Math.abs(dX) === Math.abs(dY) || (hover.currentlyHovering && collidedShape.id !== hover.id)) {
			if (hover.border === 'top') {
				if (collidedShape.positionY !== 0 && collidedShape.id !== hover.id) {
					movement = { direction: 'down', distance: 0 };
				} else {
					movement = { direction: 'down', distance: down };
				}
			} else if (hover.border === 'left') {
				if (collidedShape.positionX !== 0 && collidedShape.id !== hover.id) {
					movement = { direction: 'right', distance: 0 };
				} else {
					movement = { direction: 'right', distance: right };
				}
			} else if (hover.border === 'bottom') {
				movement = { direction: 'down', distance: down };
			} else {
				movement = { direction: 'right', distance: right };
			}

			return movement;
		}

		//Priority on Horizontal Move
		if (Math.abs(dX) >= Math.abs(dY)) {
			if (dX >= 0) {
				movement = { direction: 'right', distance: right };
			} else {
				movement = { direction: 'left', distance: left };
			}
		} else {
			if (dY >= 0) {
				movement = { direction: 'down', distance: down };
			} else {
				movement = { direction: 'up', distance: up };
			}
		}

		return movement;
	};

	const setNewShapePosition = (index: number, movement: any, passedShapes: Shape[]): Shape[] => {
		switch (movement.direction) {
			case 'left':
				passedShapes[index] = {
					...passedShapes[index],
					positionX: passedShapes[index].positionX - movement.distance,
				};
				break;
			case 'right':
				passedShapes[index] = {
					...passedShapes[index],
					positionX: passedShapes[index].positionX + movement.distance,
				};

				break;
			case 'up':
				passedShapes[index] = {
					...passedShapes[index],
					positionY: passedShapes[index].positionY - movement.distance,
				};
				break;
			case 'down':
				passedShapes[index] = {
					...passedShapes[index],
					positionY: passedShapes[index].positionY + movement.distance,
				};
				break;
			default:
				break;
		}

		return passedShapes;
	};

	const collisionTree = (
		interactingShape: Shape,
		passedShapes: Shape[],
		previousMovement: any = null,
	): { collision: boolean; cannotMove: boolean; resultingShapes: Shape[] } => {
		let collision = false;
		let cannotMove = false;
		let resultingShapes = passedShapes;

		for (let i = 0; i < passedShapes.length; i++) {
			//Determine If Self
			if (interactingShape.id === passedShapes[i].id) continue;

			//Check For Collision Between Passed Shape And Looped Shape (Returns Collision Overlap)
			const collisionTest = aabb(interactingShape, passedShapes[i]);

			//If No Collision With Looped Shape Move To Next Shape
			if (collisionTest.success) {
				collision = true;
			} else {
				continue;
			}

			//If Collision With Looped Shape
			//  Determine Best Move For Looped Shape (Get Out Of Way)
			//    If Movement calculated earlier in tree use same movement?
			let movement = determineBestMovement2(passedShapes[i], interactingShape, collisionTest, previousMovement);

			//Set Data For Looped As Though You Are Making Best Move (Get Out Of Way)
			const tempShapes = setNewShapePosition(i, movement, passedShapes);

			//Test Bounds of Shape in New Position
			const tempShapeInvalid = isOutOfBounds(tempShapes[i]);

			//If OOB Return As Cannot Move (Return As Children Irrelevent)
			if (tempShapeInvalid) {
				cannotMove = true;
				break;
			}

			//Check For Collision From Move of Looped Shape (Should Recurse)
			const childCollisionShapeResults = collisionTree(tempShapes[i], tempShapes, movement);

			//If Collision Returns Cannot Move Pass Value Up
			if (childCollisionShapeResults.cannotMove) {
				cannotMove = true;
				break;
			}

			//If No Collision, Move Was Valid, We Can Return The Tested Shapes Array
			if (!childCollisionShapeResults.collision && !cannotMove) {
				resultingShapes = tempShapes;
			}
		}

		return { collision: collision, cannotMove: cannotMove, resultingShapes: resultingShapes };
	};

	const checkForCollision = (interactingShape: Shape) => {
		let collision = false;
		let collisionData: any = [];

		shapes.forEach((shape) => {
			if (interactingShape.id === shape.id) return;

			const collisionTest = aabb(interactingShape, shape);

			if (collisionTest.success === true) {
				collision = true;
				collisionData.push(collisionTest);
			}
		});

		return { status: collision, collisionData: collisionData };
	};

	const snapShape = (newShapes: Shape[], index: number) => {
		//Get Hover Index
		const hoveredIndex = shapes.findIndex((shape: Shape) => shape.id === hover.id);

		//Determine Attempted Snap Position
		switch (hover.border) {
			case 'left':
				newShapes[index] = {
					...newShapes[index],
					positionX: newShapes[hoveredIndex].positionX - newShapes[index].width,
					positionY: newShapes[hoveredIndex].positionY,
				};
				break;
			//top
			case 'right':
				newShapes[index] = {
					...newShapes[index],
					positionX: newShapes[hoveredIndex].positionX + newShapes[hoveredIndex].width,
					positionY: newShapes[hoveredIndex].positionY,
				};
				break;
			case 'top':
				newShapes[index] = {
					...newShapes[index],
					positionX: newShapes[hoveredIndex].positionX,
					positionY: newShapes[hoveredIndex].positionY - newShapes[index].height,
				};
				break;
			case 'bottom':
				newShapes[index] = {
					...newShapes[index],
					positionX: newShapes[hoveredIndex].positionX,
					positionY: newShapes[hoveredIndex].positionY + newShapes[hoveredIndex].height,
				};
				break;
			default:
				break;
		}

		//Determine if Attempted Snap Position is OOB
		const posOOB = isOutOfBounds(newShapes[index]);
		const collision = checkForCollision(newShapes[index]);
		let collisionIndex = 0;

		//Get Collision Index
		if (collision.status === true) {
			collisionIndex = shapes.findIndex((shape: Shape) => shape.id === collision.collisionData[0].id);
		}

		if (posOOB) {
			//Replace With Hovered Shape Position
			if (hover.border === 'top') {
				newShapes[index] = {
					...newShapes[index],
					positionX: newShapes[hoveredIndex].positionX,
					positionY: 0,
				};
			} else if (hover.border === 'left') {
				newShapes[index] = {
					...newShapes[index],
					positionX: 0,
					positionY: newShapes[hoveredIndex].positionY,
				};
			}
		} else if (collision.status && (newShapes[collisionIndex].positionY === 0 || newShapes[collisionIndex].positionX === 0)) {
			if (hover.border === 'top') {
				newShapes[index] = {
					...newShapes[index],
					positionX: newShapes[hoveredIndex].positionX,
					positionY: newShapes[collisionIndex].positionY + newShapes[collisionIndex].height,
				};
			} else if (hover.border === 'left') {
				newShapes[index] = {
					...newShapes[index],
					positionX: newShapes[collisionIndex].positionX + newShapes[collisionIndex].width,
					positionY: newShapes[hoveredIndex].positionY,
				};
			}
		}

		return newShapes;
	};

	const isOutOfBounds = (shape: Shape) => {
		if (shape.positionX <= -1 || shape.positionY <= -1) {
			return true;
		}

		return false;
	};

	// a:InteractingShape, b: targetShape
	const aabb = (a: Shape, b: Shape) => {
		const right = a.positionX + a.width - b.positionX;
		const left = b.positionX + b.width - a.positionX;
		const up = b.positionY + b.height - a.positionY;
		const down = a.positionY + a.height - b.positionY;

		const success =
			a.positionX < b.positionX + b.width &&
			a.positionX + a.width > b.positionX &&
			a.positionY < b.positionY + b.height &&
			a.positionY + a.height > b.positionY;

		return {
			success: success,
			left: left > 0 ? left : 0,
			right: right > 0 ? right : 0,
			up: up > 0 ? up : 0,
			down: down > 0 ? down : 0,
			id: b.id,
		};
	};

	useEffect(() => {
		setGenerating(true);
		const shapeOriginals = generateShapes(cardInstances);
		setShapes(shapeOriginals);
		setGenerating(false);
	}, [cardInstances]);

	useEffect(() => {
		const handleResize = () => {
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return !generating ? (
		<Box>
			{cardInstances.length > 0 ? (
				<Box>
					<Box
						id="bufferBoxV"
						sx={{
							position: 'absolute',
							right: `calc(1% + 31px)`,
							top: 100,
							width: '1.5%',
							height: maxHeight,
							zIndex: 100,
							display: verticleScroll ? 'inline' : 'none',
							[theme.breakpoints.down('md')]: {
								display: 'none',
							},
						}}
					/>
					<Box
						id="bufferBoxH"
						sx={{
							position: 'absolute',
							bottom: `calc(1% + 30px)`,
							left: 0,
							width: maxWidth,
							height: '1.5%',
							zIndex: 100,
							background: '#ffffff',
							display: horizaontalScroll ? 'inline' : 'none',
							[theme.breakpoints.down('md')]: {
								display: 'none',
							},
						}}
					/>
					<Box
						id="Canvas"
						sx={{
							overflowY: 'auto',
							overflowX: 'auto',
							height: maxHeight,
							width: maxWidth,
							fontSize: 0,
							position: 'relative',
							padding: standardScreen ? '.5%' : '0', //50px padding in most screens but card border/margin
							background: '#ffffff',
							display: standardScreen ? 'inline-block' : 'flex',
							flexWrap: 'wrap',
							zIndex: 10,
							'&::-webkit-scrollbar': {
								background: '#ffffff',
								borderRadius: '50px',
								width: '30px',
								height: '30px',
								[theme.breakpoints.down('md')]: {
									display: 'none',
								},
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#00000023',
								border: '8px solid transparent',
								borderRadius: '50px',
								backgroundClip: 'content-box',
							},
							'&::-webkit-scrollbar-track': {
								background: '#ffffff',
								borderRadius: '50px',
								outline: '#00000023 solid 2px',
								outlineOffset: -4,
								zIndex: 100,
							},
							'&::-webkit-scrollbar-button': {
								height: '8%',
								width: '8%',
							},
							userSelect: 'none', // suppress default text selection
						}}
						ref={ref}
						onScroll={scrollEvent}
					>
						<Box
							id="scrollBox"
							sx={{
								position: 'absolute',
								width: `calc(${scrollWidth}px - 1%)`,
								height: `calc(${scrollHeight}px - 1%`,
								zIndex: -1,
							}}
						/>
						{cardInstances.map((instance: any, index: number) => {
							return (
								<CanvasCard
									key={instance.id}
									allowEdit={allowEdit}
									filterFields={instance.filterFields}
									shapes={shapes}
									positionX={shapes[index].positionX}
									positionY={shapes[index].positionY}
									width={shapes[index].width}
									height={shapes[index].height}
									hover={hover}
									setHover={setHover}
									index={index}
									dropShape={dropShape}
									collisionDetection={checkForCollision}
									initMovementCalc={initMovementCalc}
									resizeShape={resizeShape}
									setShapeRecoveryData={setShapeRecoveryData}
									cardInstanceId={instance.id}
									cardInstanceName={instance.name}
									setDisablePointer={setDisablePointer}
									scrollWidth={scrollWidth}
									scrollHeight={scrollHeight}
									disablePointer={disablePointer}
									scrollPositionY={scrollPositionY}
									scrollPositionX={scrollPositionX}
									reRenderComponent={reRenderComponent}
									impersonation={{
										impersonation: impersonation ?? false,
										impersonatedUserId: impersonatedUserId ?? -1,
									}}
									sizeRestrictions={{
										maxHeight: parseInt(maxCardHeight),
										maxWidth: parseInt(maxCardWidth),
									}}
								/>
							);
						})}
					</Box>
				</Box>
			) : (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: `calc(100vh - 100px)`,
						background: '#ffffff',
					}}
				>
					<Typography sx={{ fontWeight: '500', color: '#c7c7c7' }}>No Charts Currently Added To Dashboard</Typography>
				</Box>
			)}
			<div>
				{allowEdit && userIsAdmin && (
					<div>
						{!updated ? (
							<Fab
								disabled={!updated}
								onClick={(): void => {
									callAPI();
									setUpdated(false);
								}}
								sx={{
									position: 'fixed',
									bottom: '20px',
									right: '20px',
									'&:hover': { background: '#33ad65', color: '#264a5d' },
								}}
								color="primary"
								aria-label="save"
							>
								<SaveIcon />
							</Fab>
						) : (
							<Tooltip title="Save">
								<Fab
									disabled={false}
									onClick={(): void => {
										callAPI();
										setUpdated(false);
									}}
									sx={{
										position: 'fixed',
										bottom: '20px',
										right: '20px',
										'&:hover': { background: '#33ad65', color: '#264a5d' },
									}}
									color="primary"
									aria-label="save"
								>
									<SaveIcon />
								</Fab>
							</Tooltip>
						)}
						<CreateCardInstance
							disabled={updated}
							reRenderComponent={reRenderComponent}
							dashboardId={dashboardId}
							shapes={shapes}
							impersonatedUserId={impersonatedUserId ?? null}
						/>
						<RemoveRelationDashboard dashboardId={dashboardId} reRenderComponent={reRenderComponent} />
					</div>
				)}
				{!impersonation && (
					<SetDefaultDashboard
						defaultDashboardId={defaultDashboardId}
						currentDashboardId={dashboardId}
						reRenderComponent={reRenderComponent}
					/>
				)}
			</div>
		</Box>
	) : (
		<Loading />
	);
};

export default DashboardCanvas;
