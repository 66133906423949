import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';
import { createColumns } from '../../../../utils/dataGrid/createColumn';
//import { IconButton } from '@mui/material';
//import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//import { useNavigate } from 'react-router-dom';

export const EntityTable = (props: { entities: any }) => {
	const { entities } = props;

	const { columnsWidth } = useColumnsWidth(4);

	const columns = createColumns([
		'name',
		'id',
		'createdOn',
		'modifiedOn'
	], columnsWidth);

	const rows = entities.map((entity: any) => {
		return {
			key: entity.id,
			more: entity.id,
			...entity,
		};
	});

	return <DetailsTable rows={rows} columns={columns} />;
};
