//Node Modules
import React, { ReactElement, useState } from 'react';

//Internal
import { EDIT_ENTITY_CARDS_URL } from '../../../Shared/constants/routes';
import { NodeDialog, EditRelationsIconWrapper } from '../../../Shared/components';
import { NodeBase, NodeBasic } from '../../../Shared/interfaces';
import CardRelationshipSelect from './CardRelationshipSelect';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const EditEntityCardRelationships = (props: { entity: NodeBasic; reRenderComponent: Function }): ReactElement => {
	const { reRenderComponent, entity } = props;
	const [entityCardSet, setEntityCardSet] = useState<string[]>([]);

	const resetComponent = (): void => {
		setEntityCardSet([]);
		reRenderComponent();
	};

	const entityPayload: NodeBase = {
		name: entity.name,
		entityId: entity.id,
		cardIds: entityCardSet,
	};

	return (
		<NodeDialog
			ButtonComponent={EditRelationsIconWrapper}
			url={EDIT_ENTITY_CARDS_URL}
			node={entityPayload}
			dialogText={'Edit Cards'}
			dialogTitle={`Edit Cards Available To ${entity.name}`}
			dialogContentText="Please select all cards this entity should have permissions to view and edit."
			reRenderComponent={resetComponent}
			buttonColor={'primary'}
			Icon={EditOutlinedIcon}
		>
			<CardRelationshipSelect id={entity.id} entityCardSet={entityCardSet} setEntityCardSet={setEntityCardSet} />
		</NodeDialog>
	);
};
