import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { GridBaseColDef } from '@mui/x-data-grid/models/colDef/gridColDef';


export const toDetailsNodeAction = () => {
	return {
		field: 'details',
		headerName: 'Details',
		sortable: false,
		width: 100,
		disableClickEventBubbling: true,
		renderCell: (params: any) => {
			return ToDetailsActionButton({id: params.id})
		}
	}
}

const ToDetailsActionButton = (props: { id: string }) => {
	const navigate = useNavigate();

	const onClick = () => {
		navigate(props.id);
	};

	return <Button variant="contained" color="secondary" onClick={onClick}>Manage</Button>;
};

export const createDeleteAction = (ButtonComponent: React.ComponentType<{id: string, name: string, reRenderComponent: Function}>): GridBaseColDef => {

	return {
		field: 'delete',
		headerName: 'Delete',
		sortable: false,
		width: 100,
		renderCell: (params: any) => {
			const { id, name, reRenderComponent } = params.row;

			return <ButtonComponent id={id} name={name} reRenderComponent={reRenderComponent} />
		}
	}
}

export const deleteRestoreAction = (ButtonComponent: React.ComponentType<{id: string, name: string, reRenderComponent: Function}>, RestoreComponent: any) => {
	return {
		field: 'delete',
		headerName: 'Delete',
		sortable: false,
		width: 100,
		renderCell: (params: any) => {
			const { id, name, deleted, reRenderComponent } = params.row;

			return deleted ?
				<RestoreComponent name={name} id={id} reRenderComponent={reRenderComponent} /> :
				<ButtonComponent id={id} name={name} reRenderComponent={reRenderComponent} />
		}
	}
}
