import { NodeIconButton } from './NodeIconButton';
import React from 'react';


export const EditRelationsIconWrapper = (props: any) => {
	return (
		<NodeIconButton {...props} sx={{
			padding: '5px 10px',
			margin: '10px',
			backgroundColor: '#7FB3D5',
			color: 'black',
			borderRadius: '20px',
			'&:hover': {
				backgroundColor: '#5499C7',
			}
		}} />
	)
}
