//Node Modules
import { GridBaseColDef } from '@mui/x-data-grid/models/colDef/gridColDef';

//Internal
import { NodeType } from '../../../interfaces';


export const generateNodeHeaders = (nodeType: NodeType, windowWidth?: number, mobile?: boolean): GridBaseColDef[] => {
	const standardWidth = mobile ? 50 : 150;

	const commonColumns: GridBaseColDef[] = [
		{ field: 'name', headerName: 'Name', width: standardWidth },
	]

	const actionColumnsWidth = nodeType.actions.reduce((acc, item) => {
		return acc + (item.width || standardWidth);
	}, 0)
	const commonColumnsWidth = commonColumns.reduce((acc, item) => {
		return acc + (item.width || standardWidth);
	}, 0)

	let infoColumnWidth: number | null = null;

	if ( windowWidth ) {
		const _wW = windowWidth * (mobile ? 0.95 : 0.85);

		const availableWidth = _wW - commonColumnsWidth - actionColumnsWidth;

		if (availableWidth < standardWidth*nodeType.infoFields.length) {
			infoColumnWidth = standardWidth;
		} else {
			infoColumnWidth = Math.floor(availableWidth / nodeType.infoFields.length);
		}
	}

	const nodeTypesInfoFields = nodeType.infoFields.map((field) => {
		return { field: field.key, headerName: field.displayKey, width: infoColumnWidth || 240 }
	});

	const actionColumns: GridBaseColDef[] = nodeType.actions;

	return [
		...commonColumns,
		...nodeTypesInfoFields,
		...actionColumns
	];
};
