//Node Modules
import React, { ReactElement, useState } from 'react';

//Material UI
import { Box, TextField, Typography, useTheme } from '@mui/material';
import Edit from '@mui/icons-material/Edit';

//Internal
import { CARD_INSTANCE_EDIT_URL } from '../../Shared/constants/routes';
import { NodeDialog } from '../../Shared/components';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { editCardValidation } from '../../ManageCards/constants/validationSchemas';
import { DialogButton } from '../../Shared/components/DialogButton';

export const EditCardInstance = (props: any): ReactElement => {
	const { reRenderComponent, cardInstance } = props;
	const theme = useTheme();

	const [name, setName] = useState<string>(cardInstance.cardInstanceName || '');

	const cardInstanceData = {
		id: cardInstance.id,
		name: name,
	};

	const onClose = () => {
		setName(cardInstance.name || '')
	}

	return (
		<NodeDialog
			ButtonComponent={DialogButton}
			url={CARD_INSTANCE_EDIT_URL}
			node={cardInstanceData}
			dialogText={'Edit'}
			dialogTitle={'Edit Card'}
			reRenderComponent={reRenderComponent}
			buttonColor={'primary'}
			dialogContentText="Editing card details. Name must be unique. Please include any relevant filter fields for data lookup in DOMO."
			Icon={EditOutlinedIcon}
			validationSchema={editCardValidation}
			dialogButtonText={
				<Edit
					sx={{ alignSelf: 'flex-start', color: theme.palette.error.main, opacity: '.05', '&:hover': { opacity: '1' } }}
				/>
			}
			resetContent={onClose}
		>
			<Box sx={{ marginTop: '10px' }}>
				<Typography sx={{ fontWeight: 700, fontSize: '14px', marginBottom: '5px' }}>Details</Typography>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Card Name"
					type="text"
					fullWidth
					variant="standard"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
			</Box>
		</NodeDialog>
	);
};
