//Internal
import { CreateUser, DeleteUser, RestoreUser } from '../../ManageUsers/components';
import { CreateCard, DeleteCard } from '../../ManageCards/components';
import { ImpersonateUser } from '../components';
import { NodeTypes } from '../interfaces';
import {
	CreateEntity,
	DeleteEntity,
	RestoreEntity,
} from '../../ManageEntities/components';
import { DeleteDashboard } from '../../ManageDashboards/components/DeleteDashboard';
import { openDashboardAction } from '../../ManageDashboards/components/actions/OpenDashboardButton';
import { CreateDashboard } from '../components/CreateDashboard';
import DISPLAY from './displayValueFunctions';
import { createDeleteAction, deleteRestoreAction, toDetailsNodeAction } from './nodeActions';
import { impersonateAction } from '../../ManageUsers/components/actions/impersonateAction';

export const NODE_TYPES: NodeTypes = {
	Card: {
		name: 'Card',
		route: '/cards',
		createComponent: (reRenderComponent: Function) => <CreateCard reRenderComponent={reRenderComponent} />,
		hasSoftDelete: false,
		infoFields: [
			{key: 'embedId', displayKey: 'Embed ID'},
			{key: 'identifierField', displayKey: 'Lookup field'},
			{key: 'entities', displayKey: 'E'},
			{key: 'instances', displayKey: 'I'},
		],
		actions: [
			toDetailsNodeAction(),
			createDeleteAction(DeleteCard),
		],
	},
	Entity: {
		name: 'Entity',
		route: '/entities',
		createComponent: (reRenderComponent: Function) => <CreateEntity reRenderComponent={reRenderComponent} />,
		hasSoftDelete: true,
		infoFields: [
			{key: 'vertical', displayKey: 'Vertical'},
			{key: 'level', displayKey: 'Level'},
			{key: 'users', displayKey: 'U'},
			{key: 'cards', displayKey: 'C'},
		],
		actions: [
			// TODO: Add synced cation;
			toDetailsNodeAction(),
			deleteRestoreAction(DeleteEntity, RestoreEntity),
		],
	},
	User: {
		name: 'User',
		route: '/users',
		impersonation: (user: any) => <ImpersonateUser userId={user.id} />,
		createComponent: (reRenderComponent: Function) => <CreateUser reRenderComponent={reRenderComponent} />,
		hasSoftDelete: true,
		infoFields: [
			{key: 'email', displayKey: 'Email'},
			{key: 'role', displayKey: 'Role', getDisplayValue: DISPLAY.USER.ROLE},
			{key: 'entities', displayKey: 'E'},
			{key: 'instances', displayKey: 'I'},
		],
		actions: [
			toDetailsNodeAction(),
			deleteRestoreAction(DeleteUser, RestoreUser),
			impersonateAction(),
		],
	},
	Node: {
		name: 'Node',
		route: '/nodes',
		createComponent: () => null,
		infoFields: [
			{key: 'vertical', displayKey: 'Vertical'},
			{key: 'level', displayKey: 'Level'},
			{key: 'children', displayKey: 'Children'},
			{key: 'parents', displayKey: 'Parents'},
		],
		actions: [
			toDetailsNodeAction(),
		],
	},
	Dashboard: {
		name: 'Dashboard',
		route: '/dashboards/list',
		createComponent: (reRenderComponent: Function) => <CreateDashboard reRenderComponent={reRenderComponent} />,
		infoFields: [
			{key: 'type', displayKey: 'Type'},
			{key: 'embedId', displayKey: 'Embed ID'},
			{key: 'identifierField', displayKey: 'Lookup field'},
			{key: 'isPublic', displayKey: 'Public', getDisplayValue: DISPLAY.DASHBOARD.IS_PUBLIC},
			{key: 'createdOn', displayKey: 'Created', getDisplayValue: DISPLAY.DASHBOARD.DATE_FORMATTED},
			{key: 'modifiedOn', displayKey: 'Updated', getDisplayValue: DISPLAY.DASHBOARD.DATE_FORMATTED},
		],
		actions: [
			toDetailsNodeAction(),
			openDashboardAction(),
			createDeleteAction(DeleteDashboard),
		],
	},
};
