//Node Module
import { ReactElement, useEffect, useState } from 'react';

//Material UI
import { Box } from '@mui/material';

//Internal
import { useFetch } from '../../hooks/useFetch';
import { NodeFull, NodeType } from '../../interfaces';
import { ResponseHandler } from '..';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { GridBaseColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { generateNodeHeaders, generateNodeRows } from './utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export interface NodeListSearchCriteria {
	property: keyof NodeFull;
	value: string;
}

interface NodeListSearchParams {
	[key: string]: any;
}

const NodeList = (props: {
	nodeType: NodeType;
	searchCriteria: NodeListSearchCriteria;
	searchParams?: NodeListSearchParams;
	reRenderComponent: Function;
	reRender: Boolean;
}): ReactElement => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const handleResize = () => {
		setWindowWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));
	const { nodeType, searchCriteria, searchParams, reRender, reRenderComponent } = props;

	let url = nodeType.route;

	if (searchParams) {
		const params = new URLSearchParams();
		for (const [name, value] of Object.entries(searchParams)) {
			params.set(name, JSON.stringify(value));
		}
		url = `${url}?${params.toString()}`;
	}

	const request = useFetch<NodeFull[]>(url, true, [reRender]);

	const columns: GridBaseColDef[] = generateNodeHeaders(nodeType, windowWidth, mobile);

	const loadedData = request.data ? request.data : [];

	return (
		<ResponseHandler request={request}>
			<Box id="NodeList" sx={{
				width: mobile ? '98vw' : '85vw',
				height: '82vh',
				margin: '1%',
				overflow: 'hidden',
			}}>
				<DataGrid
					sx={{
						'--DataGrid-overlayHeight': '300px',
						border: 'none',
					}}
					columns={columns}
					rows={generateNodeRows(loadedData, searchCriteria, nodeType, reRenderComponent)}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 25,
							},
						},
					}}
					pageSizeOptions={[25, 50, 100]}
					disableRowSelectionOnClick
				/>
			</Box>
		</ResponseHandler>
	);
};

export default NodeList;
