//Node Modules
import React from 'react';

//Material UI
import { Box } from '@mui/material';
import { NodeType } from '../../../Shared/interfaces';
import { EntityData, EntityTable } from './EntityTable';
import { InstanceData, InstanceTable } from './InstanceTable';
import CardTable, { CardData } from './CardTable';
import { UserData } from './UserData';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal

export const UserDetailsContent = (props: { tab: number; id: string | undefined; nodeType: NodeType; data: any, updateTitleCallback: Function, reRender: Function }) => {
	const { tab, data, updateTitleCallback, reRender } = props;
	const { entities, cards, instances } = data;
	const theme = useTheme();

	const cardsData: CardData[] = cards.map((card: CardData): CardData => {
		return {
			id: card.id,
			name: card.name,
			embedId: card.embedId,
			createdOn: card.createdOn,
			modifiedOn: card.modifiedOn,
		}
	})

	const entityData: EntityData[] = entities.map((entity: EntityData): EntityData => {
		return {
			id: entity.id,
			name: entity.name,
			createdOn: entity.createdOn,
			modifiedOn: entity.modifiedOn,
		}
	})

	const instancesData: InstanceData[] = instances.map((instance: InstanceData): InstanceData => {
		return {
			id: instance.id,
			name: instance.name,
			dashboardId: instance.dashboardId,
			modifiedOn: instance.modifiedOn,
			createdOn: instance.createdOn,
		}
	})

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<>
			{tab === 0 ? <Box
				sx={{
					width: standardScreen ? '85%' : '100%',
					overflowY: 'auto',
					'&::-webkit-scrollbar': {
						background: '#ffffff',
						borderRadius: '10px',
						width: '.75em',
						[theme.breakpoints.down('md')]: {
							display: 'none',
						},
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#00000023',
						border: '4px solid transparent',
						borderRadius: '9px',
						backgroundClip: 'content-box',
					},
					'&::-webkit-scrollbar-track': {
						background: '#ffffff',
						borderRadius: '10px',
						outline: '#00000023 solid 2px',
						zIndex: 100,
					},
					'&::-webkit-scrollbar-button': {
						height: '2.5%',
					},
				}}
			>
				<UserData data={data} updateTitleCallback={updateTitleCallback} />
			</Box> : null}
			{tab === 1 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<InstanceTable instances={instancesData} />
			</Box> : null}
			{tab === 2 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<CardTable cards={cardsData} />
			</Box> : null}
			{tab === 3 ? <Box sx={{ width: standardScreen ? '85%' : '100%', height: '100%' }}>
				<EntityTable entities={entityData} user={{id: data.id}} reRender={reRender} />
			</Box> : null}
		</>
	);
};
