//Node Modules
import React, { useState } from 'react';

//Material UI
import { Box, Typography } from '@mui/material';

//Internal
import { formatDate } from '../../../../utils/dateFormatter';
import { NodeBase, NodeFull } from '../../../Shared/interfaces';
import { useSubmit } from '../../../Shared/hooks/useSubmit';
import { CARD_EDIT_URL } from '../../../Shared/constants/routes';
import { DataField } from '../../../Shared/components/NodeDetailsFields/DataField';
import EditableField from '../../../Shared/components/NodeDetailsFields/EditableField';
import { EditIdentifierField } from './CustomEditFields';
import { stringValidation } from '../../../ManageUsers/constants/validationSchemas';


export const CardData = (props: { data: any, updateTitleCallback: Function }) => {
	const { data, updateTitleCallback } = props;
	const { card } = data;

	const [name, setName] = useState<string>(card.name || '');
	const [identifierField, setIdentifierField] = useState<string>(card.identifierField || '');

	const cardData: NodeBase = {
		name: name,
		id: card.id,
		embedId: card.embedId,
		identifierField: identifierField,
	};

	const { callAPI, resetState } = useSubmit<NodeFull>(CARD_EDIT_URL, cardData);

	const handleSubmitEdit = () => {
		callAPI();
		resetState();
		updateTitleCallback(name);
	}

	return (
		<Box
			sx={{
				padding: '2% 2%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				minHeight: '100%',
			}}
		>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Basic Info
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<EditableField
						label={'Card Name'}
						value={cardData.name || 'Undefined'}
						onChange={setName}
						onSubmit={handleSubmitEdit}
						validationSchema={stringValidation}
					/>
					<DataField label={'Embed Id'} value={card.embedId} />
					<DataField label={'Id'} value={card.id} />
					<DataField label={'Status'} value={card.delete ? 'Disabled' : 'Active'} />
					<EditableField
						label={'Identify Field'}
						value={cardData.identifierField || 'Undefined'}
						onChange={setIdentifierField}
						onSubmit={handleSubmitEdit}
						customEditComponent={EditIdentifierField}
						validationSchema={stringValidation}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
					margin: '10px 0',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Timestamps
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Created On'} value={formatDate(card.createdOn)} />
					<DataField label={'Created By'} value={card.createdBy} />
					<DataField label={'Modified On'} value={formatDate(card.modifiedOn)} />
					<DataField label={'Modified By'} value={card.modifiedBy} />
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>Usage</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Entities'} value={data.entities?.length ?? 0} />
					<DataField label={'Users'} value={data.users?.length ?? 0} />
					<DataField label={'Instances'} value={data.instances?.length ?? 0} />
				</Box>
			</Box>
		</Box>
	);
};
