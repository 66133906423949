//Node Modules
import React, { ReactElement, useEffect, useRef } from 'react';

interface EmbedCardResolution {
	height: number | string;
	width: number | string;
}

interface EmbedCardProps {
	embedToken: string;
	embedUrl: string;
	resolution?: EmbedCardResolution;
	disablePointer?: boolean;
}

interface Resolution {
	height: string;
	width: string;
}

//TODO - Review for future dashboard changes. No Edits Pending Rewrite

export const EmbedCard = (props: EmbedCardProps): ReactElement => {
	const { embedUrl, embedToken, resolution, disablePointer } = props;

	const ref = useRef<HTMLIFrameElement | null>(null);

	const _resolution: Resolution = {
		width: '600',
		height: '600',
	};
	if (resolution) {
		_resolution.height = resolution.height.toString();
		_resolution.width = resolution.width.toString();
	}

	useEffect(() => {
		if (ref.current) {
			const iframe = ref.current;
			iframe.srcdoc = getGeneratedPageURL(embedToken, embedUrl);
		}
	}, [embedToken, embedUrl]);

	const pointerEvents = disablePointer ? 'none' : 'auto';

	return (
		<iframe
			title="embedCardFrame"
			ref={ref}
			id="iframe"
			src=""
			width={'100%'}
			height={'100%'}
			min-height={'265px'}
			style={{ border: 'none', pointerEvents: pointerEvents }}
		/>
	);
};

function getGeneratedPageURL(embedToken: string, embedUrl: string): string {
	return (
		'' +
		'<html>\n' +
		'   <body>\n' +
		'        <form id="form" action="' +
		embedUrl +
		'" method="post">\n' +
		'        <input type="hidden" name="embedToken" value="' +
		embedToken +
		'">\n' +
		'        </form>\n' +
		'        <script>\n' +
		'        document.getElementById("form").submit();\n' +
		'        </script>\n' +
		'    </body>\n' +
		'</html>\n'
	);
}
