import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { EditDashboardConnectedUsers } from './EditDashboardConnectedUsers';
import { EditRelationsActionsBox } from '../../../Shared/components';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';
import { createColumns } from '../../../../utils/dataGrid/createColumn';


export default function UserTable(props: { dashboard: any, reRender: () => void }) {
	const { dashboard, reRender } = props;
	const { users } = dashboard;

	const { columnsWidth } = useColumnsWidth(6);

	const columns = createColumns([
		'lastName',
		'firstName',
		'email',
		'id',
		'createdOn',
		'modifiedOn',
	], columnsWidth);

	const rows = users.map((user: any) => {
		return {
			key: user.id,
			name: `${user.firstName} ${user.lastName}`,
			more: user.id,
			...user,
		};
	});

	return <>
			<EditRelationsActionsBox>
				<EditDashboardConnectedUsers dashboardId={dashboard.id} users={users} reRenderComponent={reRender} />
			</EditRelationsActionsBox>
			<DetailsTable rows={rows} columns={columns} />
		</>
}
