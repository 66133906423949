//Node Modules
import React, { useState } from 'react';

//Material UI
import { Box, Typography } from '@mui/material';
import { formatDate } from '../../../../utils/dateFormatter';

//Internal
import { DataField } from '../../../Shared/components/NodeDetailsFields/DataField';
import EditableField from '../../../Shared/components/NodeDetailsFields/EditableField';
import { NodeFull } from '../../../Shared/interfaces';
import { useSubmit } from '../../../Shared/hooks/useSubmit';
import { ENTITIES_EDIT_URL } from '../../../Shared/constants/routes';
import { titleCase } from '../../../../utils';
import { stringValidation } from '../../../ManageUsers/constants/validationSchemas';

export const EntityData = (props: { data: any, updateTitleCallback: Function }) => {
	const { data, updateTitleCallback } = props;

	const [name, setName] = useState<string>(data.name || '');

	const editedData: Partial<NodeFull> = {
		entityData: {
			id: data.id,
			name: name,
			deleted: data.deleted,
			synced: data.synced,
		},
		graphData: {
			vertical: data.vertical,
			level: data.level,
		}
	};

	const { callAPI, resetState } = useSubmit<NodeFull>(ENTITIES_EDIT_URL, editedData);

	const handleSubmitEdit = () => {
		callAPI();
		resetState();
		updateTitleCallback(name);
	}

	return (
		<Box
			id="infoSection"
			sx={{
				padding: '2% 2%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				minHeight: '100%',
			}}
		>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Basic Info
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<EditableField
						label={'Entity Name'}
						value={editedData.entityData.name || ""}
						onChange={setName}
						onSubmit={handleSubmitEdit}
						validationSchema={stringValidation}
					/>
					<DataField label={'Id'} value={data.id} />
					<DataField label={'Status'} value={data.delete ? 'Disabled' : 'Active'} />
					<DataField label={'Synced'} value={data.synced ? 'True' : 'False'} />
					<DataField label={'Vertical'} value={titleCase(data.vertical)} />
					<DataField label={'Level'} value={titleCase(data.level)} />
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
					margin: '10px 0',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Timestamps
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Created On'} value={formatDate(data.createdOn)} />
					<DataField label={'Created By'} value={data.createdBy} />
					<DataField label={'Modified On'} value={formatDate(data.modifiedOn)} />
					<DataField label={'Modified By'} value={data.modifiedBy} />
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>Usage</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Entities'} value={data.entities?.length ?? 0} />
					<DataField label={'Users'} value={data.users?.length ?? 0} />
					<DataField label={'Instances'} value={data.instances?.length ?? 0} />
				</Box>
			</Box>
		</Box>
	);
};
